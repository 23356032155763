<template>
    <div>
        <!-- :class="`status-${options[value].color}`" -->
        <b-badge pill :variant="activeStatus.color">
            {{ activeStatus.text }}
        </b-badge>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            default: [
                { value: 1, text: "فعال", color: "primary" },
                { value: 2, text: "غير فعال", color: "danger" },
                { value: 3, text: "قيد الانتظار", color: "warning" },
                { value: 4, text: "غير محدد", color: "secondary" },
                { value: 5, text: "مخفية", color: "danger" }
            ]
        },
        value: {
            default: 4
        }
    },
    computed: {
        activeStatus() {
            return this.options.find(el => el.value == this.value);
        }
    }
};
</script>

<style scoped lang="scss"></style>
