<template>
    <div>
        <!--details packages-->
        <b-card>
            <template #header>
                <div class="d-flex justify-content-start align-items-center">
                    <unicon
                        name="bookmark"
                        class="mr-1"
                        style="fill: #6e6b7b"
                    ></unicon>
                    <h4 class="m-0">معلومات الحزمة</h4>
                </div>
                <div class="d-flex justify-content-start align-items-center">
                    <b-badge
                        pill
                        :variant="packageDto.isHidden ? 'danger' : 'success'"
                    >
                        {{ packageDto.isHidden ? " ملغية" : " فعالة" }}
                    </b-badge>
                </div>
            </template>
            <validationObserver ref="sellPointForm">
                <b-row>
                    <b-col lg="4" md="6">
                        <ek-input-text
                            name=" اسم الحزمة"
                            label="اسم الحزمة "
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'اسم الحزمة مطلوب',
                                },
                            ]"
                            v-model="packageDto.name"
                        ></ek-input-text>
                    </b-col>

                    <b-col lg="4" md="6" class="mt-1">
                        <b-form-group
                            label="نوع الحزمة"
                            v-slot="{ ariaDescribedby }"
                        >
                            <b-form-radio-group
                                :options="
                                    packageOptions.map((el) => ({
                                        value: el.id,
                                        text: el.name,
                                    }))
                                "
                                :aria-describedby="ariaDescribedby"
                                name="radio-options"
                                v-model="packageDto.packageType"
                                disabled
                            >
                            </b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col lg="4" md="6">
                        <b-form-checkbox
                            id="checkbox-edu"
                            name="subjectContainsEdu"
                            v-model="packageDto.subjectContainsEdu"
                            :value="true"
                            :unchecked-value="false"
                        >
                            يتضمن التعليمي
                        </b-form-checkbox>
                        <b-form-checkbox
                            id="checkbox-exam"
                            name="subjectContainsPreviousExam"
                            v-model="packageDto.subjectContainsPreviousExam"
                            :value="true"
                            :unchecked-value="false"
                        >
                            يتضمن الاختبارات
                        </b-form-checkbox>
                    </b-col>
                    <b-col lg="4" md="6">
                        <ek-input-select
                            name="الفرع"
                            label=" تابع ل جامعة - كلية - فرع "
                            placeholder="جامعة - كلية - فرع"
                            :options="departmentsSubjectsOptions"
                            v-model="packageDto.departmentsIds"
                            @change="resetSelect"
                        >
                        </ek-input-select>
                    </b-col>

                    <b-col lg="4" md="6">
                        <ek-input-select
                            name="المادة"
                            v-if="packageDto.departmentsIds &&  departmentsSubjectsOptions.find(
                                    (d) => d.id == packageDto.departmentsIds
                                )"
                            v-model="packageDto.subjectIds"
                            :options="
                                departmentsSubjectsOptions.find(
                                    (d) => d.id == packageDto.departmentsIds
                                ).subjects
                            "
                            multiple
                            label=" تابع ل سنة - فصل - مادة "
                            placeholder="تابع ل سنة - فصل - مادة "
                            @change="resetCourse"
                        >
                        </ek-input-select>
                    </b-col>

                    <b-col
                        lg="4"
                        md="6"
                        v-if="packageDto.packageType == 'Course'"
                    >
                        <ek-input-select
                            name="الكورس"
                            v-model="packageDto.courseIds"
                            :options="filteredCourses"
                            multiple
                            label=" تابع ل كورس معين  "
                            placeholder="تابع ل كورس معين  "
                        >
                        </ek-input-select>
                    </b-col>

                    <b-col lg="4" md="6">
                        <div class="d-flex">
                            <b-col lg="6" md="6">
                                <ek-input-text
                                    readonly
                                    v-model="packageDto.codesCount"
                                    name="الرموز"
                                    label="عدد الرموز"
                                >
                                </ek-input-text>
                            </b-col>
                            <b-col lg="6" md="6">
                                <ek-input-text
                                    readonly
                                    v-model="packageDto.codesActiveCount"
                                    name="المشتركين"
                                    label="عدد المشتركين"
                                ></ek-input-text>
                            </b-col>
                        </div>
                    </b-col>

                    <b-col lg="4" md="6">
                        <ek-input-text
                            name="price"
                            label="كلفة الحزمة"
                            placeholder="ادخل التكلفة"
                            v-model="packageDto.price"
                            :rules="[
                                { type: 'required', message: 'التكلفة مطلوب' },
                            ]"
                        ></ek-input-text>
                    </b-col>

                    <b-col lg="4" md="6">
                        <div class="d-flex">
                            <b-col lg="8" md="8">
                                <ek-date-picker
                                    readonly
                                    v-model="packageDto.dateCreated"
                                    name="التاريخ"
                                    label="تاريخ التوليد "
                                >
                                </ek-date-picker>
                            </b-col>
                            <b-col lg="4" md="4">
                                <ek-input-select
                                    name="النوع"
                                    label="حالة الحزمة"
                                    placeholder="  الحزمة فعالة"
                                    :options="statusDetailsPackage"
                                    v-model="packageDto.isHidden"
                                >
                                </ek-input-select>
                            </b-col>
                        </div>
                    </b-col>
                    <b-col lg="12" md="12">
                        <ek-input-textarea
                            :rules="[
                                {
                                    type: 'required',
                                    message: ' وصف الحزمة مطلوب',
                                },
                            ]"
                            name="description"
                            placeholder="ادخل  وصف الحزمة "
                            label=" وصف الحزمة  "
                            v-model="packageDto.description"
                        >
                        </ek-input-textarea>
                    </b-col>
                </b-row>
            </validationObserver>
        </b-card>

        <div class="d-flex justify-content-end mb-1">
            <b-col cols="6">
            <div class="p-1">
                    <h4>
                        القيمة الكلية المدفوعة :
                        <b-badge
                            pill
                            variant="primary"
                            class="ml-1 font-weight-bolder"
                            style="font-size: 15px"
                        >
                            {{
                                packageDto.codes.reduce(
                                    (pre, cur) => pre + cur.price,
                                    0
                                )
                            }}</b-badge
                        >
                    </h4>
                </div>
            </b-col>
            <!--dialog add codes-->
            <b-col cols="6">
                <ValidationObserver ref="codePackage">
                    <b-form>
                        <div>{{ searchVender }}</div>
                        <ek-input-select
                            clearable
                            name="المندوب"
                            placeholder="اسم المندوب"
                            v-model="searchVender"
                            :options="filterVendersOptions"
                            @change="filterVender"
                        >
                        </ek-input-select>
                        <div
                            class="d-flex justify-content-end align-items-center"
                        >
                            <ek-input-text
                                class="mx-1"
                                v-model="searchVal"
                                style="
                                    position: absolute;
                                    left: 120px;
                                    width: 150px;
                                    left: 120px;
                                "
                                name="ابحث عن رمز محدد"
                                placeholder="ابحث عن رمز محدد"
                                @input="filterSearch"
                            >
                            </ek-input-text>

                            <ek-dialog
                                ref="addDialog"
                                title="إضافة رمز "
                                size="md"
                                btnText=" رمز جديد "
                                @ok="submit()"
                                @close="reset"
                            >
                                <template #body>
                                    <ek-input-select
                                        name="حزمة "
                                        label="اختر حزمة"
                                        placeholder=" اختر حزمة معينة"
                                        :options="packageList"
                                        v-model="codesDto.packageId"
                                    >
                                    </ek-input-select>

                                    <ek-input-text
                                        type="number"
                                        name="discount"
                                        placeholder="ادخل  الحسم   "
                                        label="  الحسم  "
                                        v-model="codesDto.discount"
                                    ></ek-input-text>

                                    <ek-input-text
                                        type="number"
                                        name="period"
                                        placeholder="حدد موعد الإنتهاء "
                                        label="موعد الانتهاء  (عدد الاشهر)"
                                        v-model="codesDto.period"
                                    ></ek-input-text>

                                    <ek-input-text
                                        readonly
                                        v-if="codesDto.packageId"
                                        name="price"
                                        label="التكلفة"
                                        :value="
                                            packageList.find(
                                                (el) =>
                                                    el.id == codesDto.packageId
                                            ).price
                                        "
                                    ></ek-input-text>
                                </template>
                            </ek-dialog>
                        </div>
                    </b-form>
                </ValidationObserver>
            </b-col>
          
        </div>
        <!--table codes-->
        <ek-table
            :columns="columnsCode"
            :items="filterCodes"
            @delete-selected="deleteCode"
        >
         

            <template slot="items.endDate" slot-scope="{ value }">
                <span>{{
                    value ? new Date(value).toLocaleDateString() : "_"
                }}</span>
            </template>

            <template slot="items.startDate" slot-scope="{ value }">
                <span>{{
                    value ? new Date(value).toLocaleDateString() : "_"
                }}</span>
            </template>

            <template slot="items.isActive" slot-scope="{ value }">
                <StatusBadge :options="statusCode" :value="value"></StatusBadge>
            </template>
        </ek-table>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import StatusBadge from "@/app/global/components/StatusBadge.vue";

export default {
    components: {
        StatusBadge,
    },
    props: {
        id: String,
    },
    data: () => ({
        searchVal: "",
        searchVender: "",
        tab: 0,
    }),
    computed: {
        ...mapState({
            filteredVendersArr: (state) => state.codes.filteredVendersArr,
            universityName: (state) => state.university.universityName,
            packageOptions: (state) => state.codes.packageOptions,
            packageList: ({ codes }) => codes.packageList,
            packageDto: (state) => state.codes.packageDto,
            codesDto: (state) => state.codes.codesDto,
            columnsCode: (state) => state.codes.columnsCode,
            statusCode: ({ codes }) => codes.statusCode,
            statusDetailsPackage: (state) => state.codes.statusDetailsPackage,
            departmentsSubjectsOptions: ({ courses }) =>
                courses.departmentsSubjectsOptions,
            filteredCourses: ({ codes, courses }) =>
                courses.baseCoursesList.filter((co) =>
                    codes.packageDto.subjectIds.includes(co.subjectId)
                ),
        }),
        ...mapGetters(["filterCodes", "filterVenders"]),

        filterVendersOptions() {
            return this.filterVenders.map((vender) => ({
                id: vender.createdBy,
                name: vender.createdBy,
            }));
        },
    },
    methods: {
        ...mapActions([
            "getPackageDetails",
            "getBaseCoursesList",
           
            "addCode",
            "getPackageList",
            "deleteCode",
            "getDepartmentsWithSubjects",
        ]),
        filterSearch() {
            this.$store.commit("Filter_Codes", this.searchVal);
        },
        filterVender() {
            console.log("filterVender");
            this.$store.commit("Filter_Vender", this.searchVender);
        },
        formData(target) {
            if (target == false) return "فعالة";
            else "ملغية";
        },
        resetSelect() {
            this.packageDto.subjectIds = "";
            this.packageDto.courseIds = "";
        },
        resetCourse() {
            this.packageDto.courseIds = [];
        },

        //submit add codes
        submit() {
            this.$refs.codePackage.validate().then((success) => {
                if (success) {
                    this.addCode(this.codesDto);
                    this.$refs.addDialog.close();
                    this.$refs.codePackage.reset();
                }
            });
        },
        setSearch(query) {
            this.$store.commit("Set_Search_Dto", {
                keys: ["code", "studentName", "createdBy"],
                query,
            });
        },
        reset() {
            this.$refs["codePackage"].reset();
            Object.assign(this.codesDto, {
                packageId: "",
                discount: "",
                period: "",
            });
        },
    },
    created() {
        this.getPackageDetails(this.id);
        this.getPackageList();
        this.getDepartmentsWithSubjects(this.tab); // StudentType == 0
        this.getBaseCoursesList();
    },
};
</script>
